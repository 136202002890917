<template>
  <div class="single-worker--inner">
    <div class="single-worker--header p-5 bg-white mb-2">
      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
          <h1>{{ name }} ({{ type }})</h1>
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
import {
  BCol, BRow,
} from 'bootstrap-vue'

// tabs
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BCol,
    BRow,
  },
  data() {
    return {
      name: '',
      email: '',
      tel: '',
      type: '',
      user: {
        name: '',
        email: '',
        tel: '',
        type: '',
      },
    }
  },
  mounted() {
    const token = localStorage.getItem('accessToken')

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}User/${this.$route.params.id}`, config)
      .then(response => {
        this.name = response.data.user.Name
        this.tel = response.data.user.Tel
        this.type = response.data.user.Type
      })
      .catch(error => {
        console.log(error)
        Swal.fire(
          'Coś poszło nie tak!',
          '',
          'error',
        )
      })
  },
}
</script>
